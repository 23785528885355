import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContactForm from "../../components/contactForm"
import Hero from "../../components/hero"
import Services from "../../components/services2"
import Content from "../../components/content"

/**
 * GraphQL Query to retrieve page data
 *
 * @return {Object}
 */
export const ServicesPageQuery = graphql`
  {
    site {
      siteMetadata {
        email
        phone
      }
    }
    hero: file(relativePath: { eq: "pages/about/header-home-erick.jpg" }) {
      sharp: childImageSharp {
        fluid(
          quality: 100
          maxWidth: 1920
          duotone: { highlight: "#15181f", shadow: "#15181f", opacity: 55 }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

/**
 * Services Page Component
 * This component is used to generate the Services page
 *
 * @param {Object} props
 */
const ContratoArrendamientoPage = ({ data }) => {
  return (
    <Layout isSecondary={true}>
      <SEO title="Contratos de arrendamiento" description="" />

      <Hero
        type="simple"
        tagline="Estos son los principales servicios jurídicos que le ofrecemos"
        title="Contratos de arrendamientos"
        background={data.hero.sharp.fluid}
      />

      <Content
        tagline=""
        title="Contratos de arrendamientos"
      >
        <p>
        El fundamento legal lo encontramos en el artículo 1880 del Código Civil de Guatemala.
        </p>        
        <p>
        El arrendamiento es el contrato por el cual una de las partes se obliga a dar el uso o goce
        de una cosa por cierto tiempo, a otra que se obliga a pagar por ese uso o goce un precio determinado.
          </p>
          <p>
        Puede dar bienes en arrendamiento el propietario que tenga capacidad para contratar, así como el
        que por ley o pacto tenga esta facultad respecto de los bienes que administra.
            </p>
            <p>
           <strong> ¡¡¡Llámenos y haga su cita hoy!!! </strong>
              </p>     
      </Content>

      <Services />

      <ContactForm
        email={data.site.siteMetadata.email}
        phone={data.site.siteMetadata.phone}
      />
    </Layout>
  )
}

export default ContratoArrendamientoPage

